import { Vue } from 'vue-class-component'
import { APIURL } from '@/components/Utility/APIBase'
import SponsorsDataService from '@/services/SponsorsDataService'
import SysSponsor from '@/types/SysSponsor'

type dataReturnType = { APIURL: string; sponsors: any; error: any; }

export default class theSponsorList extends Vue {
  private sponsors: SysSponsor[] = []
  error: any = null

  readonly name : string = 'theSponsorList'
  data (): dataReturnType {
    return {
      APIURL: APIURL,
      sponsors: this.sponsors,
      error: this.error
    }
  }

  retrieveSponsors () : void {
    SponsorsDataService.getAll('', null, 'sponsor_status=true')
      .then((response) => {
        this.sponsors = response.data
      })
      .catch((err) => {
        this.error = err
      })
  }

  async mounted () : Promise<void> {
    this.retrieveSponsors()
  }
}
